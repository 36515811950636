import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End

export const configJSON = require("./config");
//@ts-ignore
window.recaptchaOptions = {
  useRecaptchaNet: true,
};

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  verified: boolean;
  //   recaptcha: ReCAPTCHA | null;
  key: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CaptchaController extends BlockComponent<Props, S, SS> {
  captcha: null;
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.captcha = null;
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      verified: false,
      key: "6LfxSj8qAAAAAHbPeedlS__rD_p147GCf8Almsc0",
      //@ts-ignore
      //recaptcha: React.createRef<ReCAPTCHA>(),
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  recaptchaRef(e: any) {
    //@ts-ignore
    this.captcha = e;
  }

  onVerify(recaptchaResponse: any) {
    console.log("recaptcha res", recaptchaResponse);
    this.setState({
      verified: true,
    });
  }

  reset() {
    //@ts-ignore
    this.captcha.reset();
  }
  // Customizable Area End
}
